import React from "react"

import '../assets/scss/init.scss'

const IndexPage = () => {

  return(
    <div className="index-page">
      <div className="introduction wrapper">
        <p className="title">Pedro is a designer and creative coder that thrives in helping creative companies and individuals to create inspiring and user-focused digital experiences.</p>
        <p className="more-information">A full website will be coming soon but meanwhile you can reach me at <a className="more-information" href="mailto:p@ppereira.no">p@ppereira.no</a></p>
      </div>
    </div>
  )
}


export default IndexPage;